@tailwind base;
@tailwind components;
@tailwind utilities; /* You can add global styles to this file, and also import other style files */

body{
  font-family: Helvetica, Arial, sans-serif;
}

markdown {
  h1 {
    font-family: Rockwell-customized;
    font-size: 4rem;
    font-weight: 700;
    line-height: 100%;
    margin-top: 0.5rem;
    margin-bottom: 2rem;
  }

  h2 {
    font-family: Rockwell-customized;
    font-size: 65px;
    font-weight: 700;
    line-height: 100%;
    margin-top: 0.5rem;
    margin-bottom: 2rem;
  }

  h3 {
    font-size: 2.5rem;
    font-weight: 700;
    margin-top: 0.5rem;
    margin-bottom: 1rem;
  }

  h4 {
    font-size: 2rem;
    font-weight: 700;
    margin-top: 0.5rem;
    margin-bottom: 1rem;
  }

  h5 {
    font-size: 1.5rem;
    font-weight: 700;
    margin-top: 0.5rem;
    margin-bottom: 1rem;
  }

  h6 {
    font-size: 1.25rem;
    font-weight: 700;
    margin-top: 0.5rem;
    margin-bottom: 1rem;
  }

  p {
    margin-top: 0;
    font-size: 21px;
    margin-bottom: 1rem;
  }

  a {
    color: #4299e1;
    text-decoration: none;
  }

  a:hover {
    color: #3182ce;
    text-decoration: none;
  }

  blockquote {
    border-color: #e2e8f0;
    border-left-width: 4px;
    font-weight: 400;
    font-style: italic;
    margin-top: 2rem;
    margin-bottom: 2rem;
    padding-left: 1.5rem;
    color: #2d3748;
    font-size: 1.125rem;
  }

  code {
    background-color: #e2e8f0;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    padding-top: 1px;
    padding-bottom: 1px;
    border-radius: 0.25rem;
    font-size: 0.875rem;
  }

  hr {
    border-bottom-width: 1px;
    border-color: #e2e8f0;
    margin-top: 3rem;
    margin-bottom: 3rem;
    border-radius: 9999px;
  }

  ul {
    @apply font-bold text-lg;
    list-style-type: disc;
    list-style-position: outside;
    margin-top: 1rem;
    margin-bottom: 1rem;
    line-height: 1.5rem;
    padding-left: 1.5rem;
  }

  ol {
    list-style-type: decimal;
    list-style-position: inside;
    font-size: 21px;
    margin-top: 1rem;
    margin-bottom: 1rem;
  }

  table tr th {
    position: relative;
    font-size: 24px;
    color: #c2c0c0;
    text-align: right;
    padding: 50px 6px 10px;
    vertical-align: bottom;
  }

  table tr th.red {
    text-align: left;
    font-size: 48px;
    color: #e61d2b;
    width: 300px;
  }

  @media screen and (max-width: 1100px) {
    table tr th.red {
      width: auto;
    }
  }

  table tr th:first-child {
    text-align: left;
    font-family: Rockwell-customized;
  }

  table tr th:not(:first-child) {
    width: 18%;
  }

  table tr th .premium {
    text-transform: uppercase;
    font-size: 17px;
    line-height: 16px;
    border-radius: 4px;
    padding: 6px 8px 4px;
    background-color: #bd9f65;
    position: absolute;
    top: 5px;
    left: -5px;
    color: #000;
  }

  table tr td {
    position: relative;
    font-size: 22px;
    text-align: right;
    padding: 25px 6px;
    line-height: 14px;
    white-space: nowrap;
  }

  table tr td a {
    font-weight: bold;
    line-height: 24px;
    text-decoration: none;
    display: inline-block;
    color: #000000;
  }

  table tr td a:hover {
    color: #e61d2b;
  }

  table tr td a > em {
    display: inline-block;
    font-style: normal;
    font-size: 0.8em;
    color: #c2c0c0;
    padding-top: 0.25rem;
  }

  table tr td:first-child {
    text-align: left;
  }

  table tr td:first-child small.option_param {
    color: #ccc;
    font-size: 90%;
    padding-left: 0.75em;
  }

  table tr td strong {
    display: block;
    font-weight: 900;
    font-size: 33px;
    white-space: nowrap;
  }

  table tr td strong.free-text {
    color: #168f0c;
    font-size: 24px;
  }

  table tr td strong span {
    font-size: 15px;
  }

  table tr td strong.no-space span {
    margin-left: 0;
  }

  table tr td .blank {
    width: 20px;
    background-color: #000;
    height: 1px;
    margin-top: 10px;
    display: inline-block;
    position: absolute;
    top: 25px;
    right: 20%;
  }

  table tr td .new-price {
    color: #e61d2b;
  }

  table tr td .crossed-out-price {
    position: relative;
  }

  table tr td .crossed-out-price:after {
    content: "";
    border-top: 1px solid #cccaca;
    width: 60px;
    display: block;
    position: absolute;
    right: 14px;
    bottom: 13px;
    transform: rotate(-20deg);
  }

  table tr td .crossed-out-price-trimmed:after {
    right: 0;
  }

  table tr td .old-price,
  table tr td .coupon-top-price {
    color: #000;
    font-size: 15px;
    font-weight: 900;
    position: relative;
    display: inline-block;
  }

  table tr td .old-price:before {
    border-bottom: 1px solid #000;
    opacity: 0.18;
    position: absolute;
    content: "";
    right: -10%;
    width: 120%;
    height: 50%;
  }

  table tr td .coupon-top-price {
    color: #e61d2b;
  }

  img[alt$=">"] {
    float: right;
  }

  img[alt$="<"] {
    float: left;
  }

  img[alt$="><"] {
    display: block;
    max-width: 100%;
    height: auto;
    margin: auto;
    float: none !important;
  }
}

@media (max-width: 1300px) {
  markdown {
    h2 {
      font-size: 40px;
    }
    p {
      font-size: 18px;
    }
  }
}
@media (max-width: 960px) {
  markdown {
    h2 {
      font-size: 38px;
    }
  }
}
@media (max-width: 639px) {
  markdown {
    h2 {
      font-size: 34px;
    }
  }
}
@media (max-width: 450px) {
  markdown {
    h2 {
      font-size: 30px;
    }
    p {
      font-size: 16px;
    }
  }
}

@font-face {
  font-family: 'Rockwell-customized';
  src: url('assets/fonts/Rockwell.woff2') format('truetype');
  font-weight: normal;
  font-style: normal;
}